import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import style from './featured-cards.mod.scss';

const FeaturedCards = ({ items, children }) => {
  return (
    <div className={style.featured}>
      <Container>
        <div className={style.featured__list}>
          {children}
        </div>
      </Container>
    </div>
  );
};

export default FeaturedCards;
