import React from 'react';

const IconWavesSlider = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.005 135">
      <defs>
        <clipPath id="a">
          <path fill="none" d="M-11 0h270.005v135H-11z" />
        </clipPath>
        <clipPath id="b">
          <path
            fill="#f8f5f1"
            d="M0 0h321.869v135H0z"
            data-name="Rectangle 154"
          />
        </clipPath>
      </defs>
      <g
        clip-path="url(#a)"
        data-name="Scroll Group 4"
        // style="isolation:isolate" leaving commented out in-case required
        transform="translate(11)"
      >
        <g clip-path="url(#b)" data-name="Group 920">
          <path
            d="M145.43 50.141s-12.546 13.112-18.64 17.819c-12.153 9.336-22.668 13.983-41.487 13.26-23.723-.861-39.351-14.729-62.416-15.079C15.082 65.979 0 70.219 0 70.219S19.828 51.88 37.149 43.76c18.6-8.61 27.827-5.34 37.757-3.386 15.469 3 42.774 16.112 70.524 9.765"
            data-name="Path 12244"
          />
          <path
            d="M175.255 24.636c-2.936.3-7.506 4.94-13.842 11.284-5.231 5.2-9.338 10.125-19.437 10.109-16.193-.221-30.1-11.389-49.129-12.8a77.78 77.78 0 0 0-22.754 2.011 77.927 77.927 0 0 1 30.573-18.155c15.36-4.835 27.311-1.284 34.163.875 14.368 4.366 24.728 8.287 40.426 6.676"
            data-name="Path 12245"
          />
          <path
            d="M213.186 6.934c-28.591.858-30.2 11.432-36.795 12.957-8.743 1.983-19.471-4.643-32.612-6.9-12.364-2.076-17.219 1.964-17.219 1.964s7.834-7.736 22.87-12.782c13.07-4.447 25.421-1.054 32.891 1.227 5.509 1.677 13.789 4.066 30.862 3.538"
            data-name="Path 12246"
          />
          <path
            d="M174.633 36.237s14.92-16.346 30.142-21.6c15.525-5.393 31.226-4.284 44.463-2.706 16.825 2.01 43.185 5.66 67.499-4.34 0 0-10.3 25.31-37.552 32.24-35.684 9.1-67.968-22.115-104.551-3.594"
            data-name="Path 12247"
          />
          <path
            d="M189.67 37.712s-16.469 1.848-27.057 13.053c-9.871 10.494-15.017 17.29-18.016 19.151 0 0 9-8.112 25.77-7.053 33.8 2.264 46.266 16.254 75.987 18.594 27.83 2.162 40.8-5.841 49.837-13.079 12.361-9.812 25.679-29.329 25.679-29.329s-28.24 13.821-68.778 7.62c-32.141-4.933-42.795-11.3-63.422-8.957"
            data-name="Path 12248"
          />
          <path
            d="M79.926 116.183s24.887-11.228 39.764-22.835c17.2-13.28 17.483-17.042 27.827-20.052 12.381-3.657 22.037-3.957 46.626 3.129 18.624 5.247 32.5 13.109 59.688 13.64 27.192.558 53.124-14.019 60.577-19.178 0 0-28.776 45.824-67.932 60.045-42.554 15.441-83.87-17.828-112.72-21.185-24.4-2.894-44.432 2.663-53.83 6.436"
            data-name="Path 12249"
          />
        </g>
      </g>
    </svg>
    
  );
};

export default IconWavesSlider;
