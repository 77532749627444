import React from 'react';
import style from './product-list.mod.scss';
import Container from '../Layout/SharedStyles/Container';
import ProductCard from './ProductCard';

const ProductList = ({ products }) => {
  return (
    <Container width="medium" pt={2} pb={2}>
      <div className={style.list}>
        <h1 className={style.list__heading}>{'Featured products'}</h1>
        <div className={style.list__grid}>
          {products.map(({ title, id, description, featureImage, hoverImage, productFeatureList, productKey }) => (
            <ProductCard
              heading={title}
              id={id}
              description={description}
              featureImage={featureImage}
              hoverImage={hoverImage}
              productFeatureList={productFeatureList}
              key={productKey}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ProductList;
