import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import style from './product-card.mod.scss';
import IconWavesSlider from '../Layout/Icons/IconWavesSlider';
import cn from 'classnames';
import MultipleButtons from '../Button/MultipleButtons';

const ProductCard = ({
  heading,
  id,
  description,
  featureImage,
  hoverImage,
  productFeatureList,
}) => {
  const filteredFeatures = productFeatureList.filter(
    (item) => item.showOnProductCard
  );

  const contactPage = useStaticQuery(graphql`
    {
      datoCmsContactPage {
        originalId
      }
    }
  `);

  const classes = cn(style.card, {
    [style[`card--no-hover-image`]]: !hoverImage,
  });

  const buttons = [
    {
      page: {
        id: id,
      },
      text: 'Learn More',
    },
    {
      page: {
        id: contactPage.datoCmsContactPage.originalId,
      },
      text: 'Contact Us',
    },
  ];

  return (
    <div className={classes}>
      <div className={style.card__image}>
        <GatsbyImage
          className={style.card__image_feature}
          image={featureImage.gatsbyImageData}
          alt={featureImage.alt}
        />
        {hoverImage && (
          <GatsbyImage
            image={hoverImage.gatsbyImageData}
            alt={hoverImage.alt || ''}
            className={style.card__image_hover}
            loading="eager"
            imgStyle={{ objectFit: `contain` }}
          />
        )}
        <span className={style.card__swirl}>
          <IconWavesSlider />
        </span>
      </div>
      <div className={style.card__content}>
        <h3 className={style.card__title}>{heading}</h3>
        <p className={style.card__description}>{description}</p>
      </div>
      <div className={style.card__list}>
        {filteredFeatures.map(({ specReference, value }) => (
          <ul className={style.card__item}>
            <li className={style.card__item__heading}>
              {specReference.titleTranslation
                ? specReference.titleTranslation
                : specReference.title}
            </li>
            <li>{value}</li>
          </ul>
        ))}
      </div>
      <MultipleButtons buttons={buttons} fullWidth={true}></MultipleButtons>
    </div>
  );
};

export default ProductCard;
