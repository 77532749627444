import React from 'react';
import cn from 'classnames';
import Button from './Button';
import style from './multiple-buttons.mod.scss';

const MultipleButtons = ({
  buttons,
  light = false,
  fullWidth = false,
  twoRowMobile = false,
  alignCenter = false,
}) => {
  const classes = cn(style.buttons__wrapper, {
    [style[`two--row--mobile`]]: twoRowMobile,
    [style[`aligned--center`]]: alignCenter,
  });

  return (
    <div className={classes}>
      {buttons.map((button, i) => (
        <Button
          recordId={button.page.id}
          className={style.split__button}
          secondary={i === 0 && buttons.length === 2 ? true : false}
          primary={i === 1 || (i === 0 && buttons.length === 1) ? true : false}
          light={light}
          dark={!light}
          fullWidth={fullWidth}
          fullWidthMobile={!fullWidth}
          key={button.id}
        >
          {button.text}
        </Button>
      ))}
    </div>
  );
};

export default MultipleButtons;
