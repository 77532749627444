import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ProductList from '../components/Product/ProductList';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import PageLead from '../components/PageLead/PageLead';
import FeaturedCardsComponent from '../components/Cards/FeaturedCardsComponent';

const SeriesPages = ({
  data: {
    datoCmsSeries: { title, id, metaTags, lead, pathways },
    allDatoCmsProduct,
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <PageLead leadText={lead} />
      <ProductList products={allDatoCmsProduct.nodes} />
      <FeaturedCardsComponent pathways={pathways}></FeaturedCardsComponent>
    </PageWrapper>
  );
};

export default SeriesPages;

export const query = graphql`
  query SeriesPages($locale: String!, $id: String!) {
    datoCmsSeries(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      lead
      id: originalId
      metaTags {
        title
        description
        image {
          url
        }
      }
      pathways {
        heading
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "270", fit: "crop", w: "440", q: 60 }
          )
        }
        link {
          ...Link
        }
        subHeading
        text
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    allDatoCmsProduct(
      filter: { locale: { eq: $locale }, series: { originalId: { eq: $id } } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        locale
        id: originalId
        title
        description
        productFeatureList {
          specReference {
            title
            titleTranslation
          }
          value
          showOnProductCard
        }
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
        hoverImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
    }
  }
`;
