import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import style from './card.mod.scss';
import cn from 'classnames';

const PathwaysCard = ({ heading, subheading, text, image, link, bg }) => {
  const classes = cn(style.card, {
    [style[`card--${bg}`]]: bg,
  });
  return (
    <div className={classes}>
      <div className={style.card__inner}>
        <div className={style.card__image}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
        </div>
        <div className={style.card__content}>
          <h6>{subheading}</h6>
          <h4>{heading}</h4>
          <div className={style.card__text}>{text}</div>
          <Button
            dark
            primary
            recordId={link[0].page.id}
            className={style.card__link}
          >
            {link[0].text}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PathwaysCard;
